<mat-card class="property-card">
  <img src="../../../assets/house.jpg" class="card-img-top" alt="...">
  <mat-card-header>
    <mat-card-subtitle class="card-text">1705 Towanda Ave, Bloomington, IL 61701</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="container">
    <p>Occupied</p>
    <div class="justify-content-between d-flex">
      <button mat-fab color="primary">
        <mat-icon>person</mat-icon>
      </button>
      <button mat-fab color="primary" (click)="openProperty()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-fab color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
