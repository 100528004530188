<div class="container h-100" id="propertyForm">
  <h1>Property:</h1>
  <div class="row">
    <table class="table col-12 justify-content-around">
      <thead>
      <tr>
        <th class="col">Name</th>
        <th class="col text-center">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of uploader.queue">
        <td class="col"><strong>{{ item?.file?.name }}</strong></td>
        <td class="col"><button type="button" class="btn btn-danger btn-xs"
                    (click)="item.remove()">
          <span class="glyphicon glyphicon-trash"></span> Remove
        </button></td>
      </tr>
      </tbody>
    </table>
    <div class="col-12 text-center">
      <button mat-raised-button class="" type="button" id="uploadButton" (click)="fileInput.click()">Upload Photos</button>
    </div>
    <input type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple hidden>
    <div class="col-12 mt-3 mb-2 p-3 border rounded">
      <form [formGroup]="propertyForm">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Address:</mat-label>
          <input matInput placeholder="address1" type="text" formControlName="address1" name="address1" id="address1">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Address 2:</mat-label>
          <input matInput placeholder="address2" type="text" formControlName="address2" name="address2" id="address2">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>City:</mat-label>
          <input matInput placeholder="city" type="text" formControlName="city" name="city" id="city">
        </mat-form-field>
        <mat-form-field class="w-50 pr-1" appearance="outline">
          <mat-label>State:</mat-label>
          <input matInput placeholder="state" type="text" formControlName="state" name="state" id="state">
        </mat-form-field>
        <mat-form-field class="w-50 pl-1" appearance="outline">
          <mat-label>Zip Code:</mat-label>
          <input matInput placeholder="65203..." type="text" formControlName="zipCode" name="zipCode" id="zipCode">
        </mat-form-field>
        <mat-form-field class="w-50 pr-1" appearance="outline">
          <mat-label>Bedrooms:</mat-label>
          <input matInput placeholder="1, 2, etc.." type="text" formControlName="bedrooms" name="bedrooms" id="bedrooms">
        </mat-form-field>
        <mat-form-field class="w-50 pl-1" appearance="outline">
          <mat-label>Bathrooms:</mat-label>
          <input matInput placeholder="1, 2, etc.." type="text" formControlName="bathrooms" name="bathrooms" id="bathrooms">
        </mat-form-field>
        <div class="row">
          <mat-form-field class="col-4" appearance="outline">
            <mat-label>Sqft:</mat-label>
            <input matInput placeholder="squareFeet" type="text" formControlName="squareFeet" name="squareFeet" id="squareFeet">
          </mat-form-field>
          <mat-form-field class="col-4" appearance="outline">
            <mat-label>Pets?</mat-label>
            <mat-select>
              <mat-option>Yes</mat-option>
              <mat-option>No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-4" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select>
              <mat-option>Apartment</mat-option>
              <mat-option>House</mat-option>
              <mat-option>Duplex</mat-option>
              <mat-option>Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field class="w-100 pl-1" appearance="outline">
          <mat-label>Description:</mat-label>
          <textarea matInput placeholder="description" type="text" formControlName="description" name="description" id="description"></textarea>
        </mat-form-field>
        <mat-form-field class="w-50 pr-1" appearance="outline">
          <mat-label>Available Date:</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="availableDate" name="availableDate" id="availableDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="w-50 pl-1" appearance="outline">
          <mat-label>Deposit Amount:</mat-label>
          <input matInput placeholder="$200.00" type="text" formControlName="deposit" name="deposit" id="deposit">
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col w-100">
      <button mat-raised-button class="float-right bg-success" type="button" id="saveButton" (click)="saveProperty()">Save</button>
    </div>
  </div>
</div>


