import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {StatusSnackComponent} from "../common/status-snack/status-snack.component";
import {FileUploader} from "ng2-file-upload";

@Component({
  selector: 'app-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.css']
})
export class PropertyFormComponent implements OnInit {

  propertyForm = new FormGroup({
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    zipCode: new FormControl('', Validators.required),
    bedrooms: new FormControl(''),
    bathrooms: new FormControl(''),
    squareFeet: new FormControl(''),
    pets: new FormControl(''),
    description: new FormControl(''),
    availableDate: new FormControl(new Date(), Validators.required),
    deposit: new FormControl()
  })
  //TODO: make fileuploader more readable/maybe make its own component
  uploader:FileUploader;
  response:string;

  constructor(
    public dialogRef: MatDialogRef<PropertyFormComponent>,
    private snackBar: MatSnackBar,
  ) {
    this.uploader = new FileUploader({
      url: "some url",
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise( (resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    })
    this.response = '';
    this.uploader.response.subscribe( res => this.response = res );
  }

  ngOnInit(): void {
  }

  saveProperty(){
    if(this.propertyForm.valid) {
      this.dialogRef.close();
      this.snackBar.openFromComponent(StatusSnackComponent,
        {duration: 8000, panelClass: ['snackbar-success'], data: 'success'})
    }
  }
}
