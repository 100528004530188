<div class="row mx-1">
  <div class="col-md-2 col-sm-12 p-1">
    <mat-card id="addNewProperty" class="h-100 p-0 property-card">
      <mat-card-content class="h-100 align-items-center d-flex justify-content-center">
        <button mat-fab class="bg-success" (click)="openDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-md-2 col-sm-12 p-1" *ngFor="let property of properties">
    <app-property [property]="property"></app-property>
  </div>
</div>

