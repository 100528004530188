import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";

@Component({
  selector: 'app-status-snack',
  templateUrl: './status-snack.component.html',
  styleUrls: ['./status-snack.component.css']
})
export class StatusSnackComponent implements OnInit {

  backgroundColor;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    console.log(data)
    data === 'success'? this.backgroundColor='green':this.backgroundColor='red'
  }

  ngOnInit(): void {
  }

}
