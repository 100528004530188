import {Component, Input, OnInit} from '@angular/core';
import {PropertyFormComponent} from "../property-form/property-form.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent implements OnInit {

  @Input() property;
  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openProperty() {
    const dialogRef = this.dialog.open(PropertyFormComponent, {
      maxHeight: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
