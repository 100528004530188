import { Component, OnInit } from '@angular/core';
import {PropertyFormComponent} from "../property-form/property-form.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.css']
})
export class PropertyListComponent implements OnInit {

  properties = ['a', 'a', 'a',1,2,3,1,2,3];
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void { }
  openDialog() {
    const dialogRef = this.dialog.open(PropertyFormComponent, {
      maxHeight: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
